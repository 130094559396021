var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-coupon-page admin-page"},[_c('div',{staticClass:"container"},[_c('AdminHeader'),_c('div',{staticClass:"heading"},[_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"divider":"<v-icon>mdi-chevron-right</v-icon>","items":[
          {
            text: 'Kampagnen',
            to: '/admin/campaigns',
          },
          {
            text: _vm.campaign.name,
            to: ("/admin/campaigns/" + (_vm.$route.params.campaignid)),
          },
          {
            text: 'Gutschein erstellen',
            to: _vm.$route.path,
          } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-breadcrumbs-item',{class:item.to == _vm.$route.path ? 'active' : '',attrs:{"to":item.to == _vm.$route.path ? '' : item.to,"exact":"","exact-active-class":"active","active-class":""}},[(item.to == _vm.$route.path)?_c('h2',{staticClass:"breadcrumbItem"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('p',{staticClass:"breadcrumbItem"},[_vm._v(_vm._s(item.text))])])]}}])})],1),_c('v-form',{ref:"couponForm",staticClass:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"for input",attrs:{"outlined":"","label":"Ausgestellt an","placeholder":"Max Mustermann","rules":[_vm.rules.required],"tabindex":"1"},model:{value:(_vm.couponForm.for),callback:function ($$v) {_vm.$set(_vm.couponForm, "for", $$v)},expression:"couponForm.for"}}),_c('v-text-field',{staticClass:"name input",attrs:{"outlined":"","label":"Name","placeholder":"Gutschein für Cafetaria","rules":[_vm.rules.required],"tabindex":"2"},model:{value:(_vm.couponForm.name),callback:function ($$v) {_vm.$set(_vm.couponForm, "name", $$v)},expression:"couponForm.name"}}),_c('div',{staticClass:"group"},[_c('v-select',{staticClass:"mode input",attrs:{"items":_vm.couponModes,"rules":[_vm.rules.required],"outlined":"","background-color":"#fafafa","label":"Modus","tabindex":"3"},on:{"change":_vm.changeMode},model:{value:(_vm.couponForm.mode),callback:function ($$v) {_vm.$set(_vm.couponForm, "mode", $$v)},expression:"couponForm.mode"}}),(_vm.couponForm.mode == 'value')?_c('v-text-field',{staticClass:"value input",attrs:{"outlined":"","label":"Wert","type":"number","suffix":"€","rules":[_vm.rules.required, _vm.rules.positive, _vm.rules.twoDecimals],"tabindex":"4"},on:{"focusout":_vm.setValueToCurrency},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{staticClass:"changeValueBtn",attrs:{"icon":""},on:{"click":function($event){_vm.couponForm = Object.assign({}, _vm.couponForm,
                  {value: (parseFloat(_vm.couponForm.value) - 1.0).toFixed(2)})}}},[_c('v-icon',[_vm._v("remove")])],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"changeValueBtn",attrs:{"icon":""},on:{"click":function($event){_vm.couponForm = Object.assign({}, _vm.couponForm,
                  {value: (parseFloat(_vm.couponForm.value) + 1.0).toFixed(2)})}}},[_c('v-icon',[_vm._v("add")])],1)]},proxy:true}],null,false,324806026),model:{value:(_vm.couponForm.value),callback:function ($$v) {_vm.$set(_vm.couponForm, "value", $$v)},expression:"couponForm.value"}}):(_vm.couponForm.mode)?_c('p',[_vm._v("Coming soon")]):_vm._e()],1),_c('v-btn',{staticClass:"submitBtn",attrs:{"color":"primary","elevation":"0","tabindex":"5","loading":_vm.loading},on:{"click":function($event){return _vm.addCoupon()}}},[_vm._v("Gutschein erstellen")])],1)],1),_c('v-dialog',{attrs:{"elevation":"10","persistent":"","max-width":"500"},model:{value:(_vm.error.active),callback:function ($$v) {_vm.$set(_vm.error, "active", $$v)},expression:"error.active"}},[_c('v-card',[(_vm.error.code === 'unknownError')?[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Unbekannter Fehler beim Erstellen des Gutscheins ")]),_c('v-card-text',[_vm._v(" Als wir versucht haben den Gutschein mit deinen Angaben zu erstellen ist ein unbekannter Fehler aufgetreten"),_c('br'),_vm._v(" Fehlercode: "+_vm._s(_vm.error.reason)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.error = { active: false, code: '', reason: null }}}},[_vm._v("OK")])],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }