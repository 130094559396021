<template>
  <div class="new-coupon-page admin-page">
    <div class="container">
      <AdminHeader />
      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          divider="<v-icon>mdi-chevron-right</v-icon>"
          :items="[
            {
              text: 'Kampagnen',
              to: '/admin/campaigns',
            },
            {
              text: campaign.name,
              to: `/admin/campaigns/${$route.params.campaignid}`,
            },
            {
              text: 'Gutschein erstellen',
              to: $route.path,
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to == $route.path ? '' : item.to"
              exact
              exact-active-class="active"
              active-class=""
              :class="item.to == $route.path ? 'active' : ''"
            >
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-form class="form" ref="couponForm" v-model="valid">
        <v-text-field
          class="for input"
          outlined
          label="Ausgestellt an"
          placeholder="Max Mustermann"
          v-model="couponForm.for"
          :rules="[rules.required]"
          tabindex="1"
        ></v-text-field>

        <v-text-field
          class="name input"
          outlined
          label="Name"
          placeholder="Gutschein für Cafetaria"
          v-model="couponForm.name"
          :rules="[rules.required]"
          tabindex="2"
        ></v-text-field>

        <div class="group">
          <v-select
            class="mode input"
            :items="couponModes"
            v-model="couponForm.mode"
            :rules="[rules.required]"
            @change="changeMode"
            outlined
            background-color="#fafafa"
            label="Modus"
            tabindex="3"
          ></v-select>

          <v-text-field
            class="value input"
            outlined
            label="Wert"
            type="number"
            suffix="€"
            v-model="couponForm.value"
            :rules="[rules.required, rules.positive, rules.twoDecimals]"
            @focusout="setValueToCurrency"
            v-if="couponForm.mode == 'value'"
            tabindex="4"
          >
            <template v-slot:prepend>
              <v-btn
                icon
                class="changeValueBtn"
                @click="
                  couponForm = {
                    ...couponForm,
                    value: (parseFloat(couponForm.value) - 1.0).toFixed(2),
                  }
                "
              >
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
            <template v-slot:append-outer>
              <v-btn
                icon
                class="changeValueBtn"
                @click="
                  couponForm = {
                    ...couponForm,
                    value: (parseFloat(couponForm.value) + 1.0).toFixed(2),
                  }
                "
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <p v-else-if="couponForm.mode">Coming soon</p>
        </div>

        <v-btn
          color="primary"
          elevation="0"
          class="submitBtn"
          tabindex="5"
          :loading="loading"
          @click="addCoupon()"
          >Gutschein erstellen</v-btn
        >
      </v-form>
    </div>

    <v-dialog v-model="error.active" elevation="10" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'unknownError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Erstellen des Gutscheins
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Gutschein mit deinen Angaben zu erstellen
            ist ein unbekannter Fehler aufgetreten<br />
            Fehlercode: {{ error.reason }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', reason: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaignNewCoupon',
  components: { AdminHeader },
  watch: {
    '$route.params.campaignid': {
      async handler(id) {
        try {
          let campaign = await this.$bind(
            'campaign',
            db.doc(`campaigns/${id}`)
          );
          if (campaign == null) {
            this.$router.replace({
              path: '/admin/campaigns',
              query: { error: 'notFound' },
            });
            return;
          }
        } catch (e) {
          this.$router.replace({
            path: '/admin/campaigns',
            query: { error: 'unknownDoc', code: e.code },
          });
          return;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      campaign: {},
      couponForm: {
        for: '',
        name: '',
        mode: '',
      },
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'E-Mail inkorrekt',
        number: (value) => !isNaN(parseFloat(value)) || 'Nur Zahlen erlaubt',
        positive: (value) =>
          parseFloat(value) > 0 || 'Nur positive Zahlen erlaubt',
        twoDecimals: (value) => {
          if (Math.floor(parseFloat(value)) === parseFloat(value)) return true;
          return (
            parseFloat(value)
              .toString()
              .split('.')[1].length <= 2 || 'Maximal 2 Dezimalstellen erlaubt'
          );
        },
      },
      valid: true,
      loading: false,
      error: {
        active: false,
        code: '',
        reason: null,
      },
    };
  },
  computed: {
    couponModes() {
      return [
        {
          text: 'Wertgutschein',
          value: 'value',
        },
        {
          text: 'Produktgutschein',
          value: 'product',
        },
        {
          text: 'Rabattgutschein',
          value: 'discount',
        },
      ];
    },
  },
  methods: {
    async addCoupon() {
      let valid = this.$refs.couponForm.validate();
      if (!valid) return;

      this.loading = true;

      let newData = {
        for: this.couponForm.for,
        name: this.couponForm.name,
        mode: this.couponForm.mode,
        deactive: false,
      };
      if (this.couponForm.mode == 'value')
        newData.value = parseFloat(
          parseFloat(this.couponForm.value).toFixed(2)
        );

      try {
        let batch = db.batch();
        let newCouponRef = db
          .doc(`campaigns/${this.$route.params.campaignid}`)
          .collection('coupons')
          .doc();
        batch.set(newCouponRef, newData);

        let privateId = Math.floor(
          10000000000000 + Math.random() * 90000000000000
        );
        batch.set(newCouponRef.collection('private').doc('---secret---'), {
          ID: privateId.toString(),
          debits: [],
        });

        await batch.commit();

        this.$router.push({
          path: `/admin/campaigns/${this.$route.params.campaignid}/coupons/${newCouponRef.id}?created`,
        });
      } catch (err) {
        this.error = { active: true, code: 'unknownError', reason: err };
      }
    },
    setValueToCurrency() {
      this.couponForm = {
        ...this.couponForm,
        value: parseFloat(this.couponForm.value).toFixed(2),
      };
    },
    changeMode(newMode) {
      if (newMode === 'value') {
        delete this.couponForm.products;
        delete this.couponForm.discount;
        this.couponForm.value = '0.00';
      }
      if (newMode === 'product') {
        delete this.couponForm.value;
        delete this.couponForm.discount;
        this.couponForm.products = [];
      }
      if (newMode === 'discount') {
        delete this.couponForm.value;
        delete this.couponForm.products;
        this.couponForm.discount = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.new-coupon-page {
  .container {
    .input {
      border-radius: 8px;

      &.for {
        margin-top: 32px;
      }
    }

    .group {
      border-radius: 8px;
      background-color: rgba(#000000, 0.04);

      & > *:last-child {
        padding: 0 8px;
      }

      .mode {
        padding: 0;
      }

      .changeValueBtn {
        margin-top: -7px;
      }
    }

    .submitBtn {
      margin: 24px 0 16px 0;
    }
  }
}
</style>
